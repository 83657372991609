import { HttpClient, provideHttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { BackendApiModule, BackendConfiguration } from '@dpdhl-iot/api/backend';
import { DataApiModule, DataConfiguration } from '@dpdhl-iot/api/data';
import { ManagementApiModule, ManagementConfiguration } from '@dpdhl-iot/api/management';
import { PreprocessorApiModule, PreprocessorConfiguration } from '@dpdhl-iot/api/preprocessor';
import {
  RulesprocessorApiModule,
  RulesprocessorConfiguration,
} from '@dpdhl-iot/api/rules-processor';
import { SettingsApiModule, SettingsConfiguration } from '@dpdhl-iot/api/settings';
import {
  ApplicationUserManagementMapperService,
  SharedModule,
  TranslationLoaderFactory,
} from '@dpdhl-iot/shared';
import { ButtonModule, SharedUiRootModule } from '@dpdhl/angular-shared-ui';
import { MSAL_CONFIG, MsalConfiguration } from '@dpdhl/angular-sso';
import {
  APPLICATION_CONFIG,
  APPLICATION_INSIGHTS_CONFIG,
  ApplicationConfig,
  ApplicationInsightsConfig,
  ASSET_VERSION,
  getDefaultApplicationRoutes,
  GlobalErrorHandlerService,
  MAPS_CONFIG,
  MapsConfig,
  ShellComponent,
  ShellRootModule,
  WITH_USER_MANAGEMENT,
} from '@dpdhl/iot-shared-ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import packageConfig from '../../../../package.json';
import { AppComponent } from './component/app.component';

const getApplicationConfig = () =>
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
  (window as any)['__applicationConfiguration'] as ApplicationConfig;

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'alerts',
    loadChildren: () => import('@dpdhl-iot/alert').then((m) => m.AlertModule),
  },
  {
    path: 'alert-rule-groups',
    loadChildren: () => import('@dpdhl-iot/alert-templates').then((m) => m.AlertTemplatesModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('@dpdhl-iot/notifications').then((m) => m.NotificationsModule),
  },
  {
    path: 'device-management',
    loadChildren: () =>
      import('@dpdhl-iot/device-management').then((m) => m.DeviceManagementModule),
  },
  {
    path: 'facilities',
    loadChildren: () => import('./feature/facility/facility.module').then((m) => m.FacilityModule),
  },
  {
    path: 'usage-overview',
    loadChildren: () =>
      import('./feature/usage-overview/usage-overview.module').then((m) => m.UsageOverviewModule),
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedUiRootModule.forRoot({
      assetVersion: packageConfig.semanticVersionInvTrack,
    }),
    ShellRootModule,
    ShellComponent,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    ButtonModule,
    BaseChartDirective,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BackendApiModule.forRoot(
      () =>
        new BackendConfiguration({
          basePath: `${getApplicationConfig().baseUris.backend}`,
        }),
    ),
    ManagementApiModule.forRoot(
      () =>
        new ManagementConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}management`,
        }),
    ),
    PreprocessorApiModule.forRoot(
      () =>
        new PreprocessorConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}preprocessor`,
        }),
    ),
    DataApiModule.forRoot(
      () =>
        new DataConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}data`,
        }),
    ),
    RulesprocessorApiModule.forRoot(
      () =>
        new RulesprocessorConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}rulesprocessor`,
        }),
    ),
    SettingsApiModule.forRoot(
      () =>
        new SettingsConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}settings`,
        }),
    ),
    RouterModule.forRoot(
      getDefaultApplicationRoutes(routes, {
        withUserManagement: true,
        withSettings: true,
        withReports: true,
      }),
    ),
  ],
  providers: [
    { provide: APPLICATION_CONFIG, useValue: getApplicationConfig() },
    { provide: ASSET_VERSION, useValue: packageConfig.semanticVersionInvTrack },
    {
      provide: MSAL_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__msalConfig'] as MsalConfiguration,
    },
    {
      provide: APPLICATION_INSIGHTS_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__appInsightsConfig'] as ApplicationInsightsConfig,
    },
    {
      provide: MAPS_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__mapsConfig'] as MapsConfig,
    },
    {
      provide: WITH_USER_MANAGEMENT,
      useExisting: ApplicationUserManagementMapperService,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    provideHttpClient(),
    provideCharts(withDefaultRegisterables()),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
