import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewConfigurationService } from '@dpdhl-iot/shared';
import { NavigationMenu, NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';
import axios, { AxiosError, AxiosResponse } from 'axios';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  applicationTitle: string[] = ['Inventory', 'Tracking'];
  navigationMenu: NavigationMenu[] = [];
  title = 'Inventory Tracking';

  constructor(
    private readonly toastNotificationService: NotificationDataService,
    private readonly viewConfigurationService: ViewConfigurationService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.interceptAxios();
  }

  public ngOnInit(): void {
    this.viewConfigurationService.currentViewConfiguration$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((viewConfigurationModel) => {
        this.navigationMenu = viewConfigurationModel.navigationMenu;
      });
  }

  protected interceptAxios(): void {
    axios.interceptors.response.use(
      async (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        this.toastNotificationService.addNotification({
          text: error.message,
          type: NotificationType.ERROR,
        });
        await Promise.reject(error);
      },
    );
  }
}
